import "core-js/modules/es.array.push.js";
import { withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-977edb16"), n = n(), _popScopeId(), n);
const _hoisted_1 = ["src"];
const _hoisted_2 = ["onClick"];
const _hoisted_3 = {
  key: 0,
  class: "arrow"
};
import { ref, reactive, onBeforeUnmount, watch } from "vue";

// 接收 props

export default {
  __name: 'MapWithMarkers',
  props: {
    imageSrc: String,
    // 地图图片的 URL
    initialPoints: Array,
    // 初始的标记点数组结构 {id, x, y}
    selectId: Number
  },
  emits: ["pointSelected", "pointChanged"],
  setup(__props, {
    expose: __expose,
    emit: __emit
  }) {
    const props = __props;
    const emit = __emit;

    // 图片的位置信息和尺寸
    const floorContainer = ref(null);
    const imgPosition = reactive({
      x: 0,
      y: 0
    });
    const imgSize = reactive({
      width: 0,
      height: 0
    });
    const dragStart = reactive({
      x: 0,
      y: 0
    });
    const dragDistance = ref(0); // 记录鼠标拖动的距离
    const isDragging = ref(false); // 标志是否正在拖动
    const floorMap = ref(null);
    const scale = ref(1); // 用来记录当前的缩放比例
    const isEditMode = ref(false);

    // 定义多个点
    const points = reactive([...props.initialPoints]);
    const selectedPointId = ref(props.selectId); // 当前选中的点

    // 监听 initialPoints 的变化，并同步更新 points
    watch(() => props.selectId, newPoints => {
      selectedPointId.value = newPoints;
    });
    watch(() => props.initialPoints, newPoints => {
      points.splice(0, points.length, ...newPoints); // 先清空 points，然后再插入新的点
    }, {
      deep: true
    } // 深度监听以捕捉数组内部的变化
    );

    // 当图片加载后，设置初始尺寸
    const onImageLoad = () => {
      if (floorMap.value) {
        imgSize.width = floorMap.value.naturalWidth;
        imgSize.height = floorMap.value.naturalHeight;
        scale.value = 1; // 初始化缩放比例为 1
        minResizeImage();
      }
    };

    // 点击标记点，选中标记点并发射事件
    const selectPoint = id => {
      if (isEditMode.value === true || selectedPointId.value === id) {
        return;
      }
      selectedPointId.value = id;
      emit("pointSelected", id);
    };

    // 提供给外部的方法：通过传递id来选中红点
    const selectPointById = id => {
      selectedPointId.value = id;
    };
    const enterEditMode = id => {
      isEditMode.value = true;
      if (id !== null) {
        // 编辑现有的点，保存原始点的数据
        // const point = points.find((p) => p.id === id);
        // if (point) {
        selectedPointId.value = id;
        // }
      } else {
        points.push({
          id: -1,
          x: -30,
          y: -30
        });
        selectedPointId.value = -1;
      }
    };
    const getSelectPoint = () => {
      const point = points.find(p => p.id === selectedPointId.value);
      return point;
    };
    const clearSelectPoint = () => {
      const point = points.find(p => p.id === selectedPointId.value);
      point.x = -30;
      point.y = -30;
    };
    __expose({
      selectPointById,
      enterEditMode,
      getSelectPoint,
      clearSelectPoint
    });

    // 拖动逻辑
    const startDrag = event => {
      event.preventDefault(); // 阻止默认的拖放行为

      if (!floorMap.value || !floorContainer.value) return;
      dragStart.x = event.clientX;
      dragStart.y = event.clientY;
      dragDistance.value = 0;
      isDragging.value = false;
      window.addEventListener("mousemove", dragImage);
      window.addEventListener("mouseup", stopDrag);
    };
    const dragImage = event => {
      if (!floorMap.value || !floorContainer.value) return;
      const deltaX = event.clientX - dragStart.x;
      const deltaY = event.clientY - dragStart.y;
      dragDistance.value = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

      // 如果拖动的距离超过一定阈值，则认为是拖动
      if (dragDistance.value > 5) {
        isDragging.value = true;
        let newX = imgPosition.x + deltaX;
        let newY = imgPosition.y + deltaY;

        // 获取容器宽度和高度
        const containerWidth = floorContainer.value.clientWidth;
        const containerHeight = floorContainer.value.clientHeight;

        // 如果图片宽度小于等于容器宽度，锁定水平居中位置，禁止左右拖动
        if (imgSize.width <= containerWidth) {
          newX = (containerWidth - imgSize.width) / 2;
        } else {
          // 边界检查，防止左右超出容器
          if (newX > 0) newX = 0;
          if (newX < containerWidth - imgSize.width) newX = containerWidth - imgSize.width;
        }

        // 如果图片高度小于等于容器高度，锁定垂直居中位置，禁止上下拖动
        if (imgSize.height <= containerHeight) {
          newY = (containerHeight - imgSize.height) / 2;
        } else {
          // 边界检查，防止上下超出容器
          if (newY > 0) newY = 0;
          if (newY < containerHeight - imgSize.height) newY = containerHeight - imgSize.height;
        }
        imgPosition.x = newX;
        imgPosition.y = newY;
        dragStart.x = event.clientX;
        dragStart.y = event.clientY;
      }
    };
    const stopDrag = event => {
      window.removeEventListener("mousemove", dragImage);
      window.removeEventListener("mouseup", stopDrag);

      // 如果拖动距离很小，视为单击
      if (dragDistance.value <= 5 && !isDragging.value) {
        handleSingleClick(event);
      }
    };

    // 处理单击事件
    const handleSingleClick = event => {
      if (!event || isEditMode.value == false) {
        return;
      }
      const containerRect = floorContainer.value.getBoundingClientRect();
      const mouseX = event.clientX - containerRect.left;
      const mouseY = event.clientY - containerRect.top;

      // 按当前缩放比例反推实际点击的坐标
      const realX = (mouseX - imgPosition.x) / scale.value;
      const realY = (mouseY - imgPosition.y) / scale.value;
      const point = points.find(p => p.id === selectedPointId.value);
      if (point) {
        point.x = realX; // 更新为图片实际大小中的 x 坐标
        point.y = realY; // 更新为图片实际大小中的 y 坐标
      }

      emit("pointChanged", realX, realY);
      console.log("单击事件触发", realX, realY);
    };
    const minResizeImage = () => {
      const containerRect = floorContainer.value.getBoundingClientRect();
      // 获取容器宽度和高度
      const containerWidth = floorContainer.value.clientWidth;
      const containerHeight = floorContainer.value.clientHeight;

      // 判断是宽度更接近容器还是高度更接近容器
      const widthRatio = containerWidth / imgSize.width;
      const heightRatio = containerHeight / imgSize.height;
      if (widthRatio < heightRatio) {
        // 图片宽度对齐容器宽度，高度按比例调整
        imgSize.width = containerWidth;
        imgSize.height = floorMap.value.naturalHeight / floorMap.value.naturalWidth * imgSize.width;
      } else {
        // 图片高度对齐容器高度，宽度按比例调整
        imgSize.height = containerHeight;
        imgSize.width = floorMap.value.naturalWidth / floorMap.value.naturalHeight * imgSize.height;
      }
      scale.value = imgSize.width / floorMap.value.naturalWidth;
      // 居中图片
      imgPosition.x = (containerWidth - imgSize.width) / 2;
      imgPosition.y = (containerHeight - imgSize.height) / 2;
    };
    const handleWheel = event => {
      event.preventDefault();
      const containerRect = floorContainer.value.getBoundingClientRect();
      const mouseX = event.clientX - containerRect.left;
      const mouseY = event.clientY - containerRect.top;
      const offsetX = (mouseX - imgPosition.x) / imgSize.width;
      const offsetY = (mouseY - imgPosition.y) / imgSize.height;
      const scaleFactor = event.deltaY < 0 ? 1.1 : 0.9;
      const newWidth = imgSize.width * scaleFactor;
      const newHeight = imgSize.height * scaleFactor;

      // 获取容器宽度和高度
      const containerWidth = floorContainer.value.clientWidth;
      const containerHeight = floorContainer.value.clientHeight;

      // 只有在宽度或高度都超过容器时，才允许进一步缩小
      const widthExceedsContainer = newWidth >= containerWidth;
      const heightExceedsContainer = newHeight >= containerHeight;
      if (widthExceedsContainer || heightExceedsContainer) {
        scale.value *= scaleFactor; // 这里直接使用缩放比例
        imgSize.width = newWidth;
        imgSize.height = newHeight;

        // 计算新的图片位置
        let newX = imgPosition.x;
        let newY = imgPosition.y;

        // 当图片宽度大于容器宽度时，保持鼠标为中心
        if (newWidth > containerWidth) {
          newX = mouseX - newWidth * offsetX;
          // 边界检查，防止图片超出容器左右边界
          if (newX > 0) newX = 0;else if (newX < containerWidth - newWidth) newX = containerWidth - newWidth;
        } else {
          // 当图片宽度小于等于容器宽度时，靠左对齐
          newX = (containerWidth - imgSize.width) / 2;
        }

        // 当图片高度大于容器高度时，保持鼠标为中心
        if (newHeight > containerHeight) {
          newY = mouseY - newHeight * offsetY;
          // 边界检查，防止图片超出容器上下边界
          if (newY > 0) newY = 0;else if (newY < containerHeight - newHeight) newY = containerHeight - newHeight;
        } else {
          // 当图片高度小于等于容器高度时，靠上对齐
          newY = (containerHeight - imgSize.height) / 2;
        }
        imgPosition.x = newX;
        imgPosition.y = newY;
      } else {
        minResizeImage();
      }
    };

    // 清理事件监听器
    onBeforeUnmount(() => {
      stopDrag();
    });
    return (_ctx, _cache) => {
      return _openBlock(), _createElementBlock("div", {
        ref_key: "floorContainer",
        ref: floorContainer,
        class: "map-container",
        onMousedown: startDrag,
        onWheel: handleWheel
      }, [__props.imageSrc ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        ref_key: "floorMap",
        ref: floorMap,
        style: _normalizeStyle({
          position: 'absolute',
          left: `${imgPosition.x}px`,
          top: `${imgPosition.y}px`,
          width: `${imgSize.width}px`,
          height: `${imgSize.height}px`
        }),
        src: __props.imageSrc,
        onLoad: onImageLoad,
        onDragstart: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["prevent"]))
      }, null, 44, _hoisted_1)) : _createCommentVNode("", true), _createElementVNode("div", {
        style: _normalizeStyle({
          position: 'absolute',
          left: `${imgPosition.x}px`,
          top: `${imgPosition.y}px`,
          width: `${imgSize.width}px`,
          height: `${imgSize.height}px`
        })
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(points, point => {
        return _openBlock(), _createElementBlock("div", {
          key: point.id,
          style: _normalizeStyle({
            position: 'absolute',
            left: `${point.x * scale.value}px`,
            top: `${point.y * scale.value}px`,
            width: selectedPointId.value === point.id ? '30px' : '20px',
            height: selectedPointId.value === point.id ? '30px' : '20px',
            backgroundColor: selectedPointId.value === point.id ? 'rgba(1, 161, 255, 0.3)' : 'rgba(215, 29, 29, 0.3)',
            borderRadius: '50%',
            transform: 'translate(-50%, -50%)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer' // 鼠标悬停时显示手掌
          }),

          onClick: $event => selectPoint(point.id)
        }, [selectedPointId.value === point.id ? (_openBlock(), _createElementBlock("div", _hoisted_3)) : _createCommentVNode("", true), _createElementVNode("div", {
          style: _normalizeStyle({
            width: selectedPointId.value === point.id ? '20px' : '15px',
            height: selectedPointId.value === point.id ? '20px' : '15px',
            backgroundColor: selectedPointId.value === point.id ? '#01A1FF' : '#D71D1D',
            borderRadius: '50%'
          })
        }, null, 4)], 12, _hoisted_2);
      }), 128))], 4)], 544);
    };
  }
};